import {controller, target} from '@github/catalyst'

import type {UserPresence} from '@github/alive-client'
import {fromEvent} from '../subscription'
import {isFeatureEnabled} from '../features'

const AvatarStackClasses = {
  two: 'AvatarStack--two',
  threePlus: 'AvatarStack--three-plus'
}

@controller
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PresenceAvatars extends HTMLElement {
  @target avatar: HTMLImageElement

  private subscription: ReturnType<typeof fromEvent> | undefined
  private avatarsByUserId = new Map<number, HTMLImageElement>()

  connectedCallback() {
    // Grab the existing avatar from the stack and hold onto it for cloning
    const avatarContainer = this.avatar.parentElement
    const baseAvatar = this.avatar
    baseAvatar.hidden = true
    this.hidden = false

    if (!avatarContainer) {
      return
    }

    // Remove any existing avatars.  There can be some residual after a PJAX cache load
    // NOTE: we need to convert the HTMLCollection to an array so that it doesn't mutate while we are looping through it
    for (const child of Array.from(avatarContainer.children)) {
      if (child !== baseAvatar) {
        avatarContainer.removeChild(child)
      }
    }

    // register the element as a socket channel, which will cause it to automatically subscribe to events
    this.classList.add('js-socket-channel')

    const upsertAvatar = (userPresence: UserPresence) => {
      const {userId, isOwnUser, isIdle} = userPresence
      const targetOpacity = isFeatureEnabled('PRESENCE_IDLE') && isIdle ? '0.3' : '1'
      let avatar = this.avatarsByUserId.get(userId)
      if (!avatar) {
        avatar = baseAvatar.cloneNode(true) as HTMLImageElement
        // We will need to fetch the user to get the avatar url and login
        avatar.src = avatar.src.replace(/u\/[0-9]+/, `u/${userId}`)
        // This is using a depracted hovercard endpoint.
        avatar.setAttribute('data-hovercard-url', `/hovercards?user_id=${userId}`)
        avatar.hidden = false
        avatar.removeAttribute('data-target')

        // animate opacity on initial creation to fade in from nothing
        if (avatar.animate) {
          avatar.animate([{opacity: '0'}, {opacity: targetOpacity}], {duration: 500, easing: 'ease-in-out'})
        }

        if (isOwnUser) {
          // Attach own user at the bottom of the stack
          // Eventually we will remove it entirely, but it's here for now so that you always see an avatar when presence is connected
          avatarContainer.appendChild(avatar)
        } else {
          // All others get stacked in the order they show up
          avatarContainer.prepend(avatar)
        }
      }

      // set opacity so that it is correct after animiation finishes
      avatar.style.transition = 'opacity 0.5s ease-in-out'
      avatar.style.opacity = targetOpacity

      this.avatarsByUserId.set(userId, avatar)
    }

    const removeAvatar = (userId: number) => {
      const avatar = this.avatarsByUserId.get(userId)
      if (avatar) {
        avatarContainer?.removeChild(avatar)
        this.avatarsByUserId.delete(userId)
      }
    }

    // Subscribe to the presence events
    this.subscription = fromEvent(this, 'socket:presence', (event: Event) => {
      const {data: presenceUsers} = (event as CustomEvent<{data: UserPresence[]}>).detail

      const originalUserIds = this.avatarsByUserId.keys()
      const newUserIds = new Set<number>()

      for (const presenceUser of presenceUsers) {
        upsertAvatar(presenceUser)
        newUserIds.add(presenceUser.userId)
      }

      for (const userId of originalUserIds) {
        if (!newUserIds.has(userId)) {
          removeAvatar(userId)
        }
      }

      const avatarStack = avatarContainer.parentElement
      for (const className of Object.values(AvatarStackClasses)) {
        // Remove any existing classes from previous size checks
        avatarStack?.classList.remove(className)
      }

      // Add the correct class based on the number of avatars
      const count = this.avatarsByUserId.size
      if (count === 2) {
        avatarStack?.classList.add(AvatarStackClasses.two)
      } else if (count > 2) {
        avatarStack?.classList.add(AvatarStackClasses.threePlus)
      }
    })
  }

  disconnectedCallback() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
